<!--
* @Description 解决方案-虚拟建造
* @fileName index.vue
* @MethodAuthor stjh
* @Date 2023-06-30 17:20:54
-->
<template>
  <div class="aboutContainer">
    <div class="top"></div>

    <div class="con">
      <h1 class="jjfaTitle">{{ $t("virtualConstruction.section1_title") }}</h1>
      <div class="conImgH674 mb32">
        <video controls style="width: 100%">
          <source
            :src="
              this.$videBaseUrlNew +
              '2024-03-21/95c20bb9-2bd5-4cb1-a93a-09b89d81ba37.mp4'
            "
            type="video/mp4"
          />
        </video>
      </div>

      <div class="conText mb32">
        <p style="text-indent: 34px">
          {{ $t("virtualConstruction.section1_content1") }}
        </p>
        <br />
        <p style="text-indent: 34px">
          {{ $t("virtualConstruction.section1_content2") }}
        </p>
      </div>
      <div class="conImgH556 mb32">
        <img :src="imgs[0].url" alt="" />
      </div>

      <div class="conText mb32">
        <p style="text-indent: 34px">
          {{ $t("virtualConstruction.section1_content3") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgs: [
        {
          url: require("../../../assets/images/programmeImg/virtualConstruction.png"),
        },
      ],
      publicPath: process.env.BASE_URL,
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.aboutContainer {
  background: #ffffff;
  padding-bottom: 80px;
  .top {
    width: 100%;
    height: 640px;
    // background: #b6bfd6;
    background-image: url("~@/assets/images/programmeImg/XnjzBanner@3x.png");
    background-size: 100% 640px;
    background-repeat: no-repeat;
  }

  .con {
    width: 1200px;
    text-align: center;
    // padding: 0 360px;
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
